import React from 'react';
import SessionsChart from '../Dashboard/SessionsChart';

const Monitor: React.FC = () => {
    return (      
        <SessionsChart />
  );
};

export default Monitor;
