import { Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer/Footer';
import ByteTracer from './components/ByteTracer';
import SignUp from './components/SignUp/SignUp';
import SignInSide from './components/SignIn/SignInSide';
import Navigation from './components/Navigation/Navigation';
import './App.css';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={
          <>
            <AppAppBar />
            <Hero />
            <ByteTracer />
            <Footer />
          
          </>
        } />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignInSide />} /> 
        <Route path="/navigation" element={<Navigation />} /> 
      </Routes>
    </div>
  );
}

export default App;
