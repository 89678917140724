import React from 'react';
import TemplateFrame from '../SignUp/TemplateFrame';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import Overview from '../Overview/Overview';
import SideMenu from '../Dashboard/SideMenu';
import Monitor from '../Monitor/Monitor';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AppNavbar from '../Dashboard/AppNavbar';

const Navigation: React.FC = () => {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const [selectedItem, setSelectedItem] = React.useState<'overview' | 'monitor'>('overview');
  
  // Use media query to check screen size
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode as 'light' | 'dark');
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
    // Ensure no scrolling on the page
    document.body.style.overflow = 'hidden';
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const handleMenuItemClick = (item: 'overview' | 'monitor') => {
    setSelectedItem(item);
  };

  return (
    <TemplateFrame mode={mode} toggleColorMode={toggleColorMode}>
      <CssBaseline enableColorScheme />
      
      {/* Pass the mode, toggleColorMode, and handleMenuItemClick to AppNavbar */}
      <AppNavbar
        mode={mode}
        toggleColorMode={toggleColorMode}
        onMenuItemClick={handleMenuItemClick} // <-- Pass the callback here
      />
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '30px' }}>
        {!isSmallScreen && (
          <Box sx={{ width: '250px', mr: 2 }}>
            <SideMenu onMenuItemClick={handleMenuItemClick} />
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }}>
          {selectedItem === 'overview' ? <Overview mode={mode} /> : <Monitor />}
        </Box>
      </Box>
    </TemplateFrame>
  );
};

export default Navigation;
