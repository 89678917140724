import React from 'react';
import Globe from './Globe/Globe';
import StatCard, { StatCardProps } from './StatCard';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SessionsChart from './SessionsChart';
import PageViewsBarChart from './PageViewsBarChart';
import HighlightedCard from './HighlightedCard';
import Stack from '@mui/material/Stack';

interface OverviewProps {
  mode: 'light' | 'dark';
}

const data: StatCardProps[] = [
  {
    title: 'Devices Registered',
    value: '14k',
    interval: 'Last 30 days',
    trend: 'up',
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
      360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
  },
  {
    title: 'Alerts',
    value: '325',
    interval: 'Last 30 days',
    trend: 'down',
    data: [
      1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
      780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 220,
    ],
  },
  {
    title: 'Event count',
    value: '200k',
    interval: 'Last 30 days',
    trend: 'neutral',
    data: [
      500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
      520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
    ],
  },
];

const Overview: React.FC<OverviewProps> = ({ mode }) => {
  return (
    //<Globe mode={mode} />
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2), mr: 2 }}
      >
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
        <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
          <HighlightedCard />
        </Grid>
        </Grid>
        <Grid 
  container 
  spacing={2} 
  columns={11} 
  sx={{ 
    justifyContent: 'center', 
    alignItems: 'stretch' 
  }}
>
  <Grid 
    size={{ xs: 12, lg: 5 }}
    order={{ xs: 1, lg: 2 }}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Globe mode={mode} />
  </Grid>
  <Grid 
    size={{ xs: 12, lg: 6 }}
    order={{ xs: 2, lg: 1 }}
  >
    <Stack
      gap={2}
      direction={{ xs: 'column', sm: 'row', lg: 'column' }}
      sx={{ width: '100%', height: '100%' }}
    >
      <SessionsChart />
      <PageViewsBarChart />
    </Stack>
  </Grid>
</Grid>
    </Box>
  );
};

export default Overview;
