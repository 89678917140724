import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

// Define the types for the data structures
interface ChartData {
  id: string;
  label: string;
  data: number[];
  stack: string;
}

interface Axis {
  scaleType: 'band';
  categoryGapRatio: number;
  data: string[];
}

export default function DeviceMetricsBarChart() {
  const theme = useTheme();
  const colorPalette = [
    (theme.vars || theme).palette.primary.dark,
    (theme.vars || theme).palette.primary.main,
    (theme.vars || theme).palette.primary.light,
    (theme.vars || theme).palette.secondary.dark,
    (theme.vars || theme).palette.secondary.main,
    (theme.vars || theme).palette.secondary.light,
  ];

  const xAxis: Axis[] = [
    {
      scaleType: 'band',
      categoryGapRatio: 0.5,
      data: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
  ];

  const series: ChartData[] = [
    {
      id: 'device-uptime',
      label: 'Device Uptime',
      data: [50000, 48000, 51000, 53000, 49000, 51000], // Adjusted values for a smaller range
      stack: 'A',
    },
    {
      id: 'error-events',
      label: 'Error Events',
      data: [5000, 6000, 7000, 8000, 7500, 8500], // Adjusted error events values for better comparison
      stack: 'A',
    },
    {
      id: 'sensor-readings',
      label: 'Sensor Readings',
      data: [48000, 47000, 50000, 52000, 49000, 51000], // Adjusted to match uptime values
      stack: 'A',
    },
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Device Uptime, Errors, and Sensor Readings
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              500K
            </Typography>
            <Chip size="small" color="success" label="+5%" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Device metrics (Uptime, Errors, Sensor Readings) for the last 6 months
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={xAxis} // Now with type-safe xAxis
          series={series} // Now with type-safe series
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
