import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { CustomLineChart } from './ChartLibrary'; // Adjust path as needed

interface ParameterData {
  name: string;
  type: number;
  units: string;
  value: number;
}

interface SensorData {
  [parameterId: string]: ParameterData[];
}

interface WebSocketMessage {
  parameters: {
    [key: string]: {
      name: string;
      type: number;
      units: string;
      value: number;
    };
  };
  flags: object;
  message: null | string;
}

interface SessionsChartProps {}

const SessionsChart: React.FC<SessionsChartProps> = () => {
  const [sensorData, setSensorData] = useState<SensorData>({});
  const [wsStatus, setWsStatus] = useState<'connecting' | 'connected' | 'error'>('connecting');
  const theme = useTheme();
  const points = 300; // Maximum number of points to store for each sensor

  // Function to load sensor data from localStorage
  const loadSensorDataFromStorage = () => {
    const savedData = localStorage.getItem('sensorData');
    return savedData ? JSON.parse(savedData) : {};
  };

  // Function to save sensor data to localStorage
  const saveSensorDataToStorage = (data: SensorData) => {
    localStorage.setItem('sensorData', JSON.stringify(data));
  };

  // Update sensor data with WebSocket message
  const handleWebSocketMessage = useCallback((data: WebSocketMessage) => {
    if (data.parameters) {
      setSensorData((prevData) => {
        const updatedData = { ...prevData };

        Object.entries(data.parameters).forEach(([parameterId, { name, units, value }]) => {
          if (!updatedData[parameterId]) {
            updatedData[parameterId] = [];
          }
          updatedData[parameterId].push({
            name,
            type: 8, // Assuming type is fixed as '8'
            units,
            value,
          });

          // If the data array exceeds the point limit, remove the oldest data
          if (updatedData[parameterId].length > points) {
            updatedData[parameterId].shift();
          }
        });

        // Save the updated data to localStorage
        saveSensorDataToStorage(updatedData);
        return updatedData;
      });
    }
  }, [points]);

  useEffect(() => {
    // Load initial sensor data from localStorage on mount
    const initialData = loadSensorDataFromStorage();
    setSensorData(initialData);

    const API_KEY = "9e8d4305-db7d-4c2e-8d82-fb0621ce5992";
    const PASSWORD = "7dbb2efd7a44";
    const DEVICE_ID = 3504484373;

    const socket = new WebSocket(`wss://firmware.orbbyt.com/frontend?key=${API_KEY}&password=${PASSWORD}&deviceID=${DEVICE_ID}`);

    socket.onopen = () => {
      console.log('WebSocket connection established');
      setWsStatus('connected');
    };

    socket.onmessage = (event) => {
      try {
        const data: WebSocketMessage = JSON.parse(event.data);
        handleWebSocketMessage(data);
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setWsStatus('error');
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
      setWsStatus('error');
    };

    return () => {
      socket.close();
    };
  }, [handleWebSocketMessage]);

  // Define a color palette for visualizing different sensors
  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];

  return (
    <div>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Monitor {wsStatus !== 'connected' && `(${wsStatus})`}
      </Typography>

      <Grid container spacing={2} sx={{ pr: { xs: 0, sm: 2 } }}>
        <Grid item xs={12}>
          <Card variant="outlined" sx={{ width: '100%', mr: { xs: 0, sm: 2 } }}>
            <CardContent>
              <Typography component="h2" variant="subtitle2" gutterBottom>
                Sensor Data
              </Typography>
              {wsStatus === 'error' && (
                <Typography color="error" sx={{ mb: 2 }}>
                  Connection error. Please check your connection and refresh the page.
                </Typography>
              )}
              <Grid container spacing={4}>
                {Object.entries(sensorData).map(([parameterId, data], index) => {
                  const color = colorPalette[index] || theme.palette.grey[500];
                  return (
                    <Grid item xs={12} sm={6} md={6} key={parameterId}>
                      <Stack spacing={2}>
                        <Stack
                          direction="row"
                          sx={{
                            alignContent: { xs: 'center', sm: 'flex-start' },
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography variant="h4" component="p">
                            {data.length ? data[data.length - 1].value.toFixed(2) : '0.00'}
                          </Typography>
                          <Chip
                            size="small"
                            color="success"
                            label={`${data[0]?.name} (${data[0]?.units})`}
                          />
                        </Stack>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          Sensor readings for the last {data.length} data points
                        </Typography>
                        <CustomLineChart
                          parameterId={parameterId}
                          data={data}
                          color={color}
                        />
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SessionsChart;
