import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

function getDaysInMonth(month: number, year: number) {
  const date = new Date(year, month, 0);
  const monthName = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const daysInMonth = date.getDate();
  const days = [];
  let i = 1;
  while (days.length < daysInMonth) {
    days.push(`${monthName} ${i}`);
    i += 1;
  }
  return days;
}

export default function EmbeddedDeviceMonitoringChart() {
  const theme = useTheme();
  const data = getDaysInMonth(4, 2024);

  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Device Health Metrics
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              247 Devices
            </Typography>
            <Chip size="small" color="success" label="+12% Active" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Device connectivity and performance over last 30 days
          </Typography>
        </Stack>
        <LineChart
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'point',
              data,
              tickInterval: (index, i) => (i + 1) % 5 === 0,
            },
          ]}
          series={[
            {
              id: 'memory-usage',
              label: 'Memory Usage (%)',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                45, 50, 48, 55, 52, 60, 58, 62, 57, 65, 63, 68, 
                66, 70, 69, 72, 67, 74, 71, 76, 75, 78, 73, 80, 
                77, 82, 79, 84, 81, 85
              ],
            },
            {
              id: 'cpu-temp',
              label: 'CPU Temperature (°C)',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: [
                40, 42, 41, 45, 20, 47, 46, 49, 48, 80, 50, 53, 
                52, 55, 54, 57, 90, 59, 58, 61, 60, 63, 62, 65, 
                64, 67, 10, 69, 68, 70
              ],
            },
            {
              id: 'power-consumption',
              label: 'Power Consumption (mA)',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              stackOrder: 'ascending',
              data: [
                80, 85, 82, 90, 88, 95, 92, 98, 96, 100, 98, 105, 
                102, 110, 108, 115, 112, 120, 116, 125, 122, 130, 
                126, 135, 132, 140, 138, 145, 142, 150
              ],
              area: true,
            },
          ]}
          height={250}
          margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          sx={{
            '& .MuiAreaElement-series-memory-usage': {
              fill: "url('#memory-usage')",
            },
            '& .MuiAreaElement-series-cpu-temp': {
              fill: "url('#cpu-temp')",
            },
            '& .MuiAreaElement-series-power-consumption': {
              fill: "url('#power-consumption')",
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color={theme.palette.primary.dark} id="memory-usage" />
          <AreaGradient color={theme.palette.primary.main} id="cpu-temp" />
          <AreaGradient color={theme.palette.primary.light} id="power-consumption" />
        </LineChart>
      </CardContent>
    </Card>
  );
}