import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

// Define types for props
interface AreaGradientProps {
  color: string;
  id: string;
}

/**
 * AreaGradient component defines a linear gradient used for filling the area in the chart.
 */
export const AreaGradient: React.FC<AreaGradientProps> = ({ color, id }) => (
  <defs>
    <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
      <stop offset="0%" stopColor={color} stopOpacity={0.5} />
      <stop offset="100%" stopColor={color} stopOpacity={0} />
    </linearGradient>
  </defs>
);

// Define types for CustomLineChart props
interface CustomLineChartProps {
  parameterId: string;
  data: { name: string; units: string; value: number }[];
  color: string;
}

/**
 * CustomLineChart component renders a line chart for sensor data with area fill.
 */
export const CustomLineChart: React.FC<CustomLineChartProps> = ({ parameterId, data, color }) => {
  if (data.length === 0) return null; // No data

  // Prepare x-axis and y-axis data
  const xData = data.map((_, index) => index);  // Use index for x values (as simple x-axis positions)
  const yData = data.map(d => d.value);

  // Generate labels for x-axis (for example, showing just the index)
  const xLabels = data.map((_, index) => index);

  // Set min and max for x-axis
  const minX = 0;
  const maxX = data.length - 1;

  return (
    <LineChart
      colors={[color]}
      xAxis={[
        {
          data: xData,
          scaleType: 'linear',
          valueFormatter: (value) => `${xLabels[value]}`, // Simple index as x labels
          label: 'Data Point Index',
          min: minX,
          max: maxX,
        },
      ]}
      series={[
        {
          id: parameterId,
          showMark: false,
          curve: 'linear',
          area: true,
          data: yData,
        },
      ]}
      height={350}
      margin={{ left: 50, right: 20, top: 20, bottom: 60 }}
      grid={{ horizontal: true }}
      sx={{
        [`& .MuiAreaElement-series-${parameterId}`]: {
          fill: `url('#${parameterId}')`,
        },
      }}
    >
      <AreaGradient color={color} id={parameterId} />
    </LineChart>
  );
};
