import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

// Define the correct types for the `value` property
type MenuItemType = 'overview' | 'monitor';

const mainListItems = [
  { text: 'Overview', icon: <InsertChartIcon />, value: 'overview' },
  { text: 'Monitor', icon: <VisibilityIcon />, value: 'monitor' },
];

const secondaryListItems = [
  { text: 'Settings', icon: <SettingsRoundedIcon /> },
  { text: 'About', icon: <InfoRoundedIcon /> },
  { text: 'Feedback', icon: <HelpRoundedIcon /> },
];

interface MenuContentProps {
  onMenuItemClick: (item: MenuItemType) => void;  // Use the specific 'overview' | 'monitor' types
}

export default function MenuContent({ onMenuItemClick }: MenuContentProps) {
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            {/* Cast item.value to MenuItemType to satisfy TypeScript */}
            <ListItemButton onClick={() => onMenuItemClick(item.value as MenuItemType)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
