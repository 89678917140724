import React, { ReactNode } from 'react';
import { createTheme, ThemeProvider, PaletteMode } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'; // Add Button for demonstration
import getSignUpTheme from './theme/getSignUpTheme';

export interface TemplateFrameProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
  children: ReactNode;
}

const TemplateFrame: React.FC<TemplateFrameProps> = ({ mode, toggleColorMode, children }) => {
  const signUpTheme = createTheme(getSignUpTheme(mode));

  return (
    <ThemeProvider theme={signUpTheme}>
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* Add Button to toggle color mode */}
        <Button onClick={toggleColorMode} sx={{ margin: '16px' }}>
          Toggle Color Mode
        </Button>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TemplateFrame;
